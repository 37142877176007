<template>
  <form class="relative" @submit.prevent="submit">
    <input
      type="text"
      name="search"
      class="border border-solid border-blue-200 rounded-sm placeholder-blue-200 outline-none pl-10 pr-2 py-3 text-14 w-full appearance-none"
      :class="{ 'border-blue-500': isFocused }"
      :placeholder="placeholder"
      @focus="focus"
      @blur="blur"
      @input="change"
      v-model="inputValue"
      ref="input"
    >
    <span
      :class="{ 'text-blue-500': isFocused }"
      class="text-blue-200 absolute z-10 text-lg ml-3 left-0 flex items-center"
      style="top: 50%; transform: translateY(-50%);"
    >
      <ion-icon name="search-outline" />
    </span>
    <span
      :class="{ 'text-blue-500': isFocused }"
      class="cursor-pointer text-blue-200 absolute z-10 text-lg mr-3 right-0 flex items-center"
      style="top: 50%; transform: translateY(-50%);"
      @click.prevent="clear"
      v-if="inputValue.length"
    >
      <ion-icon name="close-outline" />
    </span>
  </form>
</template>

<script>
  export default {
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
      value: String,
      placeholder: String
    },
    data() {
      return {
        inputValue: '',
        isFocused: false
      }
    },
    mounted() {
      this.inputValue = this.value;
    },
    watch: {
      value(value) {
        this.inputValue = value;
      }
    },
    methods: {
      change() {
        this.$emit('change', this.inputValue);
      },
      blur() {
        this.isFocused = false;
      },
      focus() {
        this.isFocused = true;
      },
      clear() {
        this.inputValue = '';
        this.$refs.input.focus();
        this.change();
      },
      submit() {
        this.$emit('submit');
      }
    }
  }
</script>